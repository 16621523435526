import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Main from './Compiler/Main';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { DebounceInput } from 'react-debounce-input';
import Timer2 from './Timer/Timer2';
import "./styles.css"
import CodingTimer from './Timer/codingTimer';
import ConfirmationDialog from '../commonComponent/ConfirmationDialog';
import ConfirmationDialog2 from './../commonComponent/ConfirmationDialog2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmationDialogForTimer from '../commonComponent/ConfirmationDialogForTimer';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
    </Typography>
  );
}

export default function Dashboard(props) {
  const { isTime } = props;

  const [code, setCode] = useState(
    localStorage.getItem('UserData')
      ? JSON.parse(localStorage.getItem('UserData')).code
      : ''
  );
  const [input, setInput] = useState(
    localStorage.getItem('UserData')
      ? JSON.parse(localStorage.getItem('UserData')).input
      : ''
  );
  const [output, setOutput] = useState('');

  const [language, setLanguage] = useState(
    localStorage.getItem('UserData')
      ? JSON.parse(localStorage.getItem('UserData')).language
      : 'py'
  );

  const [warning, setWarning] = useState(
    localStorage.getItem('warning')
      ? Number(localStorage.getItem('warning'))
      : 0
  );

  const [value, setValue] = useState('');
  const [html, setHtml] = useState('');
  const [question, setQuestion] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [insOpen, setInsOpen] = useState(false);
  const [openTimerSubmit, setOpenTimerSubmit] = useState(false);
  const [ins, setIns] = useState([]);
  let idx = 1;
  let currentSet= Number(localStorage.getItem('currentSet'));

  const [codingQuestions,setCodingQuest]=useState(
    localStorage.getItem('codingQuestions')
      ?JSON.parse(localStorage.getItem('codingQuestions')):[])

  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }
  
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
      .then(res => {
        setIns(res.data);
    });
    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );
      if (window.innerHeight == window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {
          document.getElementById('clickButton').click()
      }
    }); 

  }, []);


  useEffect(() => {
    localStorage.setItem('UserData', JSON.stringify({ code, input, language }));
  }, [isTime]);

  const paperStyle1 = { width: '38%', height: '75vh', marginLeft: '10px' };
  const paperStyle2 = { height: '75vh', width: '60%', margin: 'auto' };
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseD = (e) => {
    e.preventDefault();
    getFullScreen();
    setOpen(false);
  };

  useEffect(()=>{
    const fetchData = async() => {
      try {
        if(location.state.currentSet===4){
          let userId = localStorage.getItem('userId');
            let questions = [
              {
                "quesID": Number(localStorage.getItem('quesID', question.id)),
                "answer": localStorage.getItem('EnglishWritingValue'),
              }
            ]
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${location.state.currentSet}`,
            {
              questions
            })
            setQuestion(response.data.questions[0]);
            setHtml(response.data.questions[0]?.question);

            const englishWritingValue = localStorage.getItem('EnglishWritingValue');
            if (englishWritingValue ) {
              setValue(englishWritingValue || '');
            } else {
              setValue(response.data.questions[0].userAns);
            }
        }else {
          setHtml(`${location.state.link}`);
          localStorage.setItem('codingQuesId', location.state.quesid);
          let userId = localStorage.getItem('userId');
            let questions = [
              {
                "quesID": Number(localStorage.getItem('codingQuesId')),
                "answer": localStorage.getItem('CodingQuestion'),
              }
            ]
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${localStorage.getItem('currentSet')}`,
            {
              questions
            });
          setCodingQuest(response.data.questions);

          if (location.state.isCodeDes) {
            const updatedQuestions = response.data.questions.map(question => {
              if (question.id === Number(localStorage.getItem('codingQuesId'))) {
                setCode(question.userAns);
              }
              return question;
            });
            setCodingQuest(updatedQuestions);
          }
        }
      } catch (error) {
        console.log("error",error)
      }
    }
    // setInterval api calling each minute..................................
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  },[]);


  const handleCloseA = () => {
    setOpen(false);
    let currentSet= Number(localStorage.getItem('currentSet'))
    let userResponse=[
      {
        "answer":location.state.isCodeDes ? code : value,
        "examID":1,
        "quesID":`${question?.id || location.state.quesid}`,
        "inputFields": currentSet===3 ? input : "",
        "language": currentSet===3 ? language : "",
        "output": output
      }
    ]
    let flag=Number(localStorage.getItem('warning'));
    let userId = localStorage.getItem('userId');
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/submitapi/`, 
        {
          "userID": userId,
          "flag":flag,
          "examAnalysis":userResponse || [],
          "isFinishSectionFlag": true,
          "sectionID": currentSet,
      })
      .then((res) => {
        let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
        if(location.state.currentSet===4){
          if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${currentSet}`]:true}))
          else localStorage.setItem('submittedSet',JSON.stringify({[`set${currentSet}`]:true}))
          navigate('/questionsummary')
        }  
        else {
          localStorage.removeItem('codingQuesId');
          localStorage.removeItem('CodingQuestion')
          if(submittedSets) 
            localStorage.setItem('submittedSet',JSON.stringify(
              {...submittedSets,
                [`set${currentSet}`]:{...submittedSets[`set${currentSet}`],[location.state.quesid]:true}
              }))
          else localStorage.setItem('submittedSet',JSON.stringify({[`set${currentSet}`]:{[location.state.quesid]:true}}))
          navigate('/questionDashboard');
        }
      });
  };


  const handleEnglishWritingChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    localStorage.setItem('EnglishWritingValue', newValue);
    localStorage.setItem('quesID', question.id)
  };


  const openDialogForSubmit=()=>{
    setOpenTimerSubmit(true)
  }

  const styles={
    fontWeight:700,
    fontSize:'1.5em',
    display:'flex',
    justifyContent:'space-between',
    margin:'20px',
    color: '#1976d2'
  }

  return (
    <>
      {(location.state.currentSet===4) ? <ConfirmationDialog open={open} handleClose={handleCloseD} submit={handleCloseA}/> :
      <ConfirmationDialog2 open={open} handleClose={handleCloseD} submit={handleCloseA}/>}

      <ConfirmationDialogForTimer open={openTimerSubmit} submit={handleCloseA} textForSubmit={`${location.state.currentSet===4 ? "Section" : "Coding question"} time has ended, Please press YES to submit.`}  />

      <Dialog
        open={insOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions:-</b>
            <br /><br />
            {ins.map((post, index) => {
            if(post.type === currentSet) {
              return (
                <div key={index}>
                  <DialogContentText>
                    {idx++}. {post.instruction}
                  </DialogContentText>
                </div>
            )}
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setInsOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', height: '89vh'}}>
        <div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0.5% 2%',
              alignItems: 'center',
              justifyContent: location.state.currentSet===4 ? 'space-between' : 'flex-end',
              gap: '50px',
            }}
          >
            {location.state.currentSet===4 && <div style={styles}>
                <span>
                  {`${location.state.part}: ${location.state.title}`}
                  {/* <IconButton onClick={()=>setInsOpen(true)}>
                    <InfoOutlinedIcon></InfoOutlinedIcon>
                  </IconButton> */}
                </span>
              </div>}
          <div style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center', gap: '50px'}}>
            {location.state.isCodeDes ? <div style={{ textAlignLast: 'center', color: '#1976d2' }}>
                <CodingTimer
                  email={localStorage.getItem('id')}
                  submit={openDialogForSubmit}
                />
              </div> : 
              
              <div style={{ textAlignLast: 'center', color: '#1976d2' }}>
                  <Timer2
                    email={localStorage.getItem('id')}
                    qid={location.state.quesid}
                    code={location.state.isCodeDes ? code : value}
                    timer={location.state.queTime}
                    submit={openDialogForSubmit}
                  />
              </div>}
              {(location.state.currentSet===4) ? "" :
              <div>
                <Button
                  id="clickButton"
                  variant="contained"
                  color="primary"
                  style={{ height: '42px', width: '172px', marginTop: '6px' }}
                  onClick={handleClickOpen}
                >
                  Submit Question
                </Button>
              </div> }
          </div> 
          </div>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 128,
                height: 128,
              },
            }}
          >
            {location.state.isCodeDes && <Paper elevation={4} style={paperStyle1}>
              <div style={{ padding: '17px 0 10px 20px', userSelect: 'none', height: '70vh' }}>
                <ReactSrcDocIframe srcDoc={html} width="95%" height="100%" style={{borderRadius:"5px" }}/>
              </div>
            </Paper>}

            {location.state.isCodeDes && 
            <Paper elevation={4} style={paperStyle2}>
                <div>
                  <Main
                    inputHandler={setInput}
                    outputHandler={setOutput}
                    languageHandler={setLanguage}
                    codeHandler={setCode}
                    code={code}
                  />
                </div>
            </Paper>
            }
          </Box>

          {!location.state.isCodeDes && <div>
            <div style={{fontSize: '20px', marginLeft: '50px', width: '91vw'}}>{html}</div>
            <div>
              <DebounceInput
                element="textarea"
                placeholder="Start Writing Here..."
                style={{
                  width: '93%',
                  height: '60vh',
                  resize: 'none',
                  margin: '15px 50px',
                  borderRadius: '5px',
                  maxHeight: '59vh',
                }}
                value={value}
                onChange={handleEnglishWritingChange}
              />
            </div>
            <div style={{display: 'flex',justifyContent: 'flex-end', marginRight: '45px'}}>
                <Button
                  id="clickButton"
                  variant="contained"
                  color="success"
                  style={{ height: '42px', width: '172px', marginTop: '6px' }}
                  onClick={handleClickOpen}
                >
                  Submit Section
                </Button>
              </div>
          </div>}

        </div>

        <div>
          <Copyright />
        </div>
      </div>
    </>
  );
}
