import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./engwriting.css"
import axios from 'axios'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EnglishWriting = (props) => {
  const [open, setOpen] = useState(false);

  const [data, setData] = useState(localStorage.getItem('writingQuestion')
  ?JSON.parse(localStorage.getItem('writingQuestion')):[]);

  const [warning, setWarning] = useState(
    localStorage.getItem('warning')
      ? Number(localStorage.getItem('warning'))
      : 0
  );
  let currentSet=Number(localStorage.getItem('currentSet'))

  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }

  useEffect(() => {
      let currentSet=Number(localStorage.getItem('currentSet'));
      let userId = localStorage.getItem('userId');
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${currentSet}`, {
          email: localStorage.getItem('id'),
        })
        .then((res) => {
          localStorage.setItem('writingQuestion',JSON.stringify(res.data.questions[0]))
          setData(res.data.questions[0]);
        });
        
    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );

      if (window.innerHeight === window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {
        document.getElementById('clickButton').click()
      }
    });
  }, []);


  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleclick = () => {
    navigate('/dashboard-exam', {
      state: {
        link: data.question,
        quesid: data.id,
        isCodeDes: data.isCode,
        isEnglishWriting: true
      },
    });
  };

  const handleFinish = () => {
    let userResponse= JSON.parse(sessionStorage.getItem('userResponse'))
    let flag=Number(localStorage.getItem('warning'));
    let userId = localStorage.getItem('userId');
    let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
    axios.post(`${process.env.REACT_APP_SERVER_URL}/submitapi/`,
    {
        "userID": userId,
        "flag":flag,
        "examAnalysis":userResponse || [],
        "isFinishSectionFlag": true,
        "sectionID": localStorage.getItem('currentSet'),
    })
    .then(res => {
        sessionStorage.removeItem('userResponse')
        if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${currentSet}`]:true}))
        else localStorage.setItem('submittedSet',JSON.stringify({[`set${currentSet}`]:true}))
        localStorage.removeItem('writingQuestion')
        localStorage.removeItem('currentSet')
        navigate('/questionsummary')

    });
  }


  const paperStyle = {
    padding: '5px 20px',
    height: '10vh',
    width: '90vw',
    margin: '28px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };


  return (
    <>  
      <div style={{display:"flex",justifyContent:"flex-end", width: '96vw', marginTop: '25px'}}>
        <Button variant="contained" color='success' onClick={handleFinish} style={{width:"80px"}}>Finish</Button>
      </div>
      <Paper elevation={7} style={paperStyle}>
        <div style={{ userSelect: 'none', width: '75%' }}>
          <h3 style={{ margin: 0 }}>
            {data.id}. {data.question}
          </h3>
        </div>
        {!data.isCode && (
          <div style={{ color: 'blue' }}>
            <p style={{ margin: 0 }}>10 Minutes</p>
          </div>
        )}
        <div>
          <p style={{ margin: 0 }}>{data.marks}</p>
        </div>
        <div>
          {!data.isSubmitted ? (
            <Button id='clickButton' variant="contained" onClick={handleclick}>
              Start
            </Button>
          ) : (
            <span style={{ color: 'green', fontSize: '17px' }}>Submitted</span>
          )}
        </div>
      </Paper>
       
    </>
  );
};

export default EnglishWriting;
