import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const Timer = (props) => {

	const {update} = props;

    const navigate = useNavigate();
	const Ref = useRef(null);

	const [timer, setTimer] = useState(localStorage.getItem('Time') ? localStorage.getItem('Time') : '01:00:00');

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}

	const startTimer = (e) => {
		let { total, hours, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
        if(total ===  120000) {
            toast.warning(`${(hours > 9 ? hours : '0' + hours) + ':' +
			(minutes > 9 ? minutes : '0' + minutes) + ':'
			+ (seconds > 9 ? seconds : '0' + seconds)} min is left`, {hideProgressBar: true, closeOnClick: true,})
        }
        if(total ===  600000) {
            toast.warning(`${(hours > 9 ? hours : '0' + hours) + ':' +
			(minutes > 9 ? minutes : '0' + minutes) + ':'
			+ (seconds > 9 ? seconds : '0' + seconds)} min is left`, {hideProgressBar: true, closeOnClick: true,})
        }
        if(total ===  900000) {
            toast.warning(`Hurry Up! ${(hours > 9 ? hours : '0' + hours) + ':' +
			(minutes > 9 ? minutes : '0' + minutes) + ':'
			+ (seconds > 9 ? seconds : '0' + seconds)} min is left`, {hideProgressBar: true, closeOnClick: true,})
        }
        else {
            if(total <= 0) {
				navigate('/exit')
            }
        }

		if( (4200000- total) % 300000 === 0){
			update(Math.random);
		}
		localStorage.setItem("Time", "" +(hours > 9 ? hours : '0' + hours) + ':' +
		(minutes > 9 ? minutes : '0' + minutes) + ':'
		+ (seconds > 9 ? seconds : '0' + seconds) )
	}

	const clearTimer = (e) => {

		setTimer('01:00:00');

		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + 3600);
		return deadline;
	}

	useEffect(() => {
		clearTimer(getDeadTime());
	}, []);

	const onClickReset = () => {
		clearTimer(getDeadTime());
	}

	return (
		<h2>{timer}</h2>
	)
}

export default Timer;
