  import React, { useEffect, useState } from 'react';
  import QuestionDetail from './questionDetails';
  import McqTimer from '../dashboard/Timer/mcqTimer';
  import axios from 'axios';
  import { useNavigate, useLocation } from 'react-router-dom';
  import ConfirmationDialog from '../commonComponent/ConfirmationDialog';
  import Typography from '@mui/material/Typography';
  import ConfirmationDialogForTimer from '../commonComponent/ConfirmationDialogForTimer';


  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
      </Typography>
    );
  }


const Question = () => {

      const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
      const [selectedQuestion, setSelectedQuestion] = useState(null);
      let CurrentSet= localStorage.getItem('currentSet');
      const [questions,setQuestions] = useState([]);
      const [isOnline, setIsOnline] = useState(true);
      const navigate = useNavigate();
      const location = useLocation();
      const [open, setOpen] = useState(false);
      const [openTest, setOpenTest] = useState(false);

      function getFullScreen() {
        return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }

  useEffect(() => {
    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );

      if (window.innerHeight === window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {      
        document.getElementById('clickButton').click()
      }
    });

    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isOnline) {
          let userId = localStorage.getItem('userId');
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${CurrentSet}`,
          {
            "questions": JSON.parse(sessionStorage.getItem('userResponse')) || []
          });
          setQuestions(response.data.questions);
          const allQquestions = response.data.questions;


          const currentIndex = allQquestions.findIndex((ques) => ques.id == localStorage.getItem('currentQuestionNo'));
          if(currentIndex != -1 ) setSelectedQuestion(response.data.questions[currentIndex]);
          else setSelectedQuestion(response.data.questions[0]);

          setCurrentQuestionIndex(currentIndex);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);

  }, []);



    const onSelectQuestion = (question) => {
      setCurrentQuestionIndex(questions.findIndex((q) => q === question));
      setSelectedQuestion(question);
      localStorage.setItem("currentQuestionNo",question.id);

    };

    const handlePreviousQuestion = () => {
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        setSelectedQuestion(questions[currentQuestionIndex - 1]);
      }
    };
  
    const handleNextQuestion = () => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedQuestion(questions[currentQuestionIndex + 1]);
      }
    };


    const submit=()=>{
      let userResponse= JSON.parse(sessionStorage.getItem('userResponse'))
      let flag=Number(localStorage.getItem('warning'));
      let userId = localStorage.getItem('userId');
      let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
      axios.post(`${process.env.REACT_APP_SERVER_URL}/submitapi/`,
      {
          "userID": userId,
          "flag":flag,
          "examAnalysis": userResponse || [],
          "isFinishSectionFlag": true,
          "sectionID": localStorage.getItem('currentSet'),
      })
      .then(res => {
          sessionStorage.removeItem('userResponse')
          if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${CurrentSet}`]:true}))
          else localStorage.setItem('submittedSet',JSON.stringify({[`set${CurrentSet}`]:true}))
          navigate('/questionsummary')
      }).catch((err)=>{
          console.log("error",err)
      })
    }

    const openDialog=()=>{
      setOpen(true)
    }

    const handleClose=(e)=>{
      e.preventDefault();
      getFullScreen();
      setOpen(false)
    }


    const openDialogForSubmit=()=>{
      setOpenTest(true)
    }


    return (
      <>
        <ConfirmationDialogForTimer open={openTest} submit={submit} textForSubmit={`Section time has ended, Please press YES to submit.`} />
        <ConfirmationDialog open={open} handleClose={handleClose} submit={submit} />

        <div style={{ display: 'flex', flexDirection: 'column', height: '91vh', justifyContent: 'space-between' }}>
          <div>

            {!isOnline ? (
              <div style={{ textAlign: 'center', color: 'red' }}>
                <p>No internet connectivity</p>
              </div>
            ) : (
              <div>
                <div style={{ textAlignLast: 'center', color: '#1976d2' }}>
                  <McqTimer
                    email={localStorage.getItem('id')}
                    submit={openDialogForSubmit}
                    part={location.state.part}
                    title={location.state.title}
                    queTime={location.state.queTime}
                  />
                </div>
                <QuestionDetail
                  question={selectedQuestion} 
                  questions={questions} 
                  CurrentSet={CurrentSet}
                  onSelectQuestion={onSelectQuestion} 
                  handlePreviousQuestion={handlePreviousQuestion} 
                  handleNextQuestion={handleNextQuestion} 
                  route={'/questionsummary'}
                  submit={openDialog}
                  id={'clickButton'}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                />
              </div>
            )}
          </div>

          <div>
            <Copyright />
          </div>
        </div>
      </>
    );
  };

export default Question;
