import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from 'react';

export default function InstructionsDashboard(props) {
  const [ins, setIns] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
    .then(res => {
      setIns(res.data);
    });
  }, []);
  
  const nav = useNavigate();
  const [open, setOpen] = useState(true);
 
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleClose = () => {
    setOpen(false);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/agreedapi/`, {
      email: localStorage.getItem("id")
    }).then(res => {
      nav('/questionsummary');
    })
  };

  let idx = 1;
  let indx = 1;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {"Instructions For The Test"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions :-</b>
          </DialogContentText>
          <ol>
            {ins.map((post, index) => {
              if(post.type === 6) {
                return (
                  <div key={index}>
                    <DialogContentText>
                      <li>
                        {post.instruction}
                      </li>
                    </DialogContentText>
                  </div>
              )
              }
            })}
          </ol>
          <DialogContentText>
            <b style={{color: 'blue'}}>Exam Instructions :-</b>
          </DialogContentText>
          <DialogContentText>
            <span><b>Coding Questions:</b></span>
          </DialogContentText>
          <ol>
            {ins.map((post, index) => {
              if(post.type === 7) {
                return (
                  <div key={index}>
                    <DialogContentText>
                      <li>{post.instruction}</li>
                    </DialogContentText>
                  </div>
              )
              }
            })}
          </ol>
          <DialogContentText>
            <span><b>Written Communication:</b></span>
          </DialogContentText>
          <ol>
            {ins.map((post, index) => {
              if(post.type === 5) {
                return (
                  <div key={index}>
                    <DialogContentText>
                      <li>{post.instruction}</li>
                    </DialogContentText>
                  </div>
              )
              }
            })}
          </ol>
        </DialogContent>
        <DialogActions style={{height: "60px"}}>
          <span style={{marginTop: '-50px', paddingTop: "10px"}}><b>By clicking on Start, you agree to follow the above instructions.</b></span>
          
          <Button variant="contained" onClick={handleClose} style={{marginRight: '10px', marginTop: "20px"}}>Start</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
