import React,{ useState } from 'react';
import Editor from "@monaco-editor/react";
import NavBar from './NavBar';
import './Main.css';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';


const Main = (props) => {
  const {inputHandler,outputHandler,languageHandler,codeHandler, code} = props;
  const [userCode, setUserCode] = useState(code);

  const [userLang, setUserLang] = useState("py");
 
  const [userTheme, setUserTheme] = useState("vs-dark");
 
  const [fontSize, setFontSize] = useState(20);
 
  const [userInput, setUserInput] = useState("");

  const [userOutput, setUserOutput] = useState("Your Output will be displayed here");

  const [loading, setLoading] = useState(false);
   
  const options = {
    fontSize: fontSize,
    contextmenu: false,
  }
  function compile() {
    
    setLoading(true);
    if (userCode === '') {
      setLoading(false)
      return
    }

    inputHandler(userInput);
    codeHandler(userCode);
    languageHandler(userLang);
    
    axios.post(`${process.env.REACT_APP_SERVER_URL}/compilerapi/`, {
      "answer" : userCode,
      "inputFields" : userInput,
      "language" : userLang
    }).then(res => {
        setLoading(false);
        setUserOutput(res.data);
        outputHandler(res.data);
    })
  };

  function handleCodingChange(value) {
    setUserCode(value);
    codeHandler(value);
    localStorage.setItem('CodingQuestion',value);
  }

  function setLanguage(value) {
    languageHandler(value);
  }

  return (
    <div className="App">
      <NavBar
        userLang={userLang} setUserLang={setUserLang}
        langSet = {setLanguage}
        userTheme={userTheme} setUserTheme={setUserTheme}
        fontSize={fontSize} setFontSize={setFontSize}
      />
      <div className="main">

        <div className="left-container">
          <Editor
            options={options}
            height="calc(89vh - 50px)"
            width="auto"
            theme={userTheme}
            language={userLang}
            defaultLanguage="python"
            value={userCode}
            onChange={handleCodingChange}
            
          />
          
          {loading ? <button className="run-btn" disabled><CircularProgress disableShrink size= '25px' color='success' /></button> : <button className="run-btn" onClick={() => compile()}>Run</button>}
        </div>
        <div className="right-container">
          <h4 style={{marginTop: '2px'}}>Input:</h4>
          <div className="input-box">
            <textarea id="code-inp" onChange={(e) => {setUserInput(e.target.value);inputHandler(e.target.value)}}>
            </textarea>
          </div>
          <h4 style={{ marginTop: '-80px' }}>Output:</h4>
          <pre className="output-box" >
              {userOutput}
          </pre>
        </div>
      </div>
    </div>
  )
}

export default Main;
