import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      SpanIdea System
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function OtpVerify() {
  const [otp, setOtp] = useState('');
  const [otpLength, setOtpLength] = useState(0);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  const handleChange = (newValue) => {
    setOtpLength(newValue.length)
    setOtp(newValue);
  };

  useEffect(() => {
    if (otpLength === 6) {
      // document.getElementById('verifyOtpButton').focus();
    }
  }, [otpLength]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (otp !== '') {
      setloading(false);
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/verifyotpapi/`, {
          otp: otp,
          email: localStorage.getItem('id'),
        })
        .then((res) => {
          localStorage.setItem('name', res.data.name);
          localStorage.setItem('collegeName', res.data.collegeName);
          localStorage.setItem("userId", res.data.userID);
          if (res.data.msg === 'otpverified') {
            toast.success('Email is Verified, Start your test!', {
              hideProgressBar: true,
              closeOnClick: true,
            });
            navigate('/instructiondashboard');
          } else {
            setloading(true);
            toast.error('Invalid OTP', {
              hideProgressBar: true,
              closeOnClick: true,
            });
          }
        });
    } else {
      setloading(true);
      toast.error('OTP field cannot be empty', {
        hideProgressBar: true,
        closeOnClick: true,
      });
      // navigate('/');
    }
  };


  const paperStyle = { padding: 35, width: '35%', margin: '28px auto' };

  return (
    <ThemeProvider theme={theme}>
      <br />
      <br />
      <div elevation={10} style={paperStyle}>
        <CssBaseline />
        <div
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding: '25px 30px',
            borderRadius: '10px',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Enter your OTP!</h1>
          <form component="form" 
            onSubmit={handleSubmit}
            sx={{ mt: 2 }} >
            <MuiOtpInput value={otp} length={6} onChange={handleChange} />
            {loading ? (
              <Button
                id="verifyOtpButton"
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 3 }}
                onClick={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }} 
                // autoFocus={otpLength === 6}
              >
                Verify OTP
              </Button>
            ) : (
              <Button
                id="fullscreen"
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled
              >
                <CircularProgress disableShrink />
              </Button>
            )}
          </form>
        </div>
      </div>
    
      <Copyright style={{position:"absolute", bottom:"0px",left:"50%",transform: "translateX(-50%)"}} sx={{ mb: 4 }} />
    </ThemeProvider>
  );
}
