import {useState} from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OtpVerify from './otpVerify/otpVerify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/dashboard/dashboard';
import Exit from './components/dashboard/Exit';
import Start from './components/dashboard/Instructions/Start';
import QuestionDashboard from './components/dashboard/Instructions/QuestionDashboard';
import NavBar from './components/navBar/NavBar';
import Email from './components/email/Email';
import React from 'react';
import Question from "./components/McqQuestion/question";
import Recording from "./components/Recording/recording";
import QuestionDashboardSummary from "./components/Questiondetails/questionDashboardSummary";
import InstructionsDashboard from "./components/Questiondetails/instructionDashboard";
import EnglishWriting from "./components/McqQuestion/englishwriting";

function App() {

const [timeUpdated, setTimeUpdated] = useState(Math.random());
const [load, setLoad] = useState(false);
 
  return (
    <>
    <Router>
      <NavBar updateTime={setTimeUpdated}  name='Examination Portal' />
        <Routes>
        <Route exact path='/' element = { <Email loadHandler = {setLoad}/> } />


        <Route exact path='/otp-verify' element = { <OtpVerify/> } />
        <Route exact path='/mcqquestion' element = { <Question /> } />
        <Route exact path='/recording' element = { <Recording /> } />
        <Route exact path='/questionsummary' element = { <QuestionDashboardSummary /> } />
        <Route exact path='/instructiondashboard' element = { <InstructionsDashboard /> } />
        <Route exact path='/exit' element = { <Exit/> } />
        <Route exact path='/instructions' element = { <Start/> } />
        <Route exact path='/questionDashboard' element = { <QuestionDashboard /> } />
        <Route exact path='/englishwriting' element = { <EnglishWriting /> } />
        <Route exact path='/dashboard-exam' element = { <Dashboard isTime={timeUpdated}/> } />
        
        </Routes>
        {load && <Routes>
        {/* <Route exact path='/otp-verify' element = { <OtpVerify/> } />
        <Route exact path='/dashboard-exam' element = { <Dashboard isTime={timeUpdated}/> } />
        <Route exact path='/exit' element = { <Exit/> } />
        <Route exact path='/mcqquestion' element = { <Question /> } />
        <Route exact path='/recording' element = { <Recording /> } />
        <Route exact path='/questionsummary' element = { <QuestionDashboardSummary /> } />
        <Route exact path='/instructiondashboard' element = { <InstructionsDashboard /> } />
        <Route exact path='/instructions' element = { <Start/> } />
        <Route exact path='/questionDashboard' element = { <QuestionDashboard /> } />
        <Route exact path='/englishwriting' element = { <EnglishWriting /> } /> */}
        </Routes>}
      </Router>
      <ToastContainer autoClose={2000} position="top-right" theme="colored"/>
    </>
  );
}

export default App;
