import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmationDialog2 = (props) => {


  return (
    <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to submit this question ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.handleClose}>NO</Button>
          <Button onClick={props.submit} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default ConfirmationDialog2;
