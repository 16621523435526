import React, { useState, useEffect,useRef } from "react";
import { toast } from 'react-toastify';


const CodingTimer = (props) => {
    const [seconds, setSeconds] = useState(1500);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

  if(seconds === 0) {
    props.submit();
  }

  useEffect(() => {
    if(seconds === 120){
      toast.warning('Two minutes left', {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  },[seconds === 120])

  useEffect(() => {   
    if(seconds === 300){
      toast.warning('Five minutes left', {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  }, [seconds === 300])

  const minutes = Math.floor(seconds / 60);
  const formattedSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;
  const styles={
    fontWeight:700,
    fontSize:'1.5em'
  }
  return (
    <>
    <div style={styles}>
      {seconds<0 ? <span>0:00</span> : <span>{`${minutes}:${formattedSeconds}`}</span>}
    </div>
    </>
  );

};

export default CodingTimer;
