import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
    </Typography>
  );
}

const theme = createTheme();
export default function Email(props) {
  const { loadHandler } = props;

  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const paperStyle = { padding: 35, width: '35%', margin: '28px auto' };
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(false);

    const data = new FormData(event.currentTarget);
    loadHandler(true);

    const id = data.get('email');

    if (data.get('email')) {
      if (!regex.test(id)) {
        setLoading(true);
        setError('Invalid email address');
        return;
      }

      try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/getotpapi/`, {
          email: id.toLowerCase(),
        });
        if (res.data === 'otpsenttomail') {
          setError('');
          toast.success('OTP has been sent to your mail', { hideProgressBar: true, closeOnClick: true });
          localStorage.setItem('id', id.toLowerCase());
          navigate('/otp-verify');
        } else if (res.data === 'invalidemailid') {
          setError('');
          setTimeout(() => {
            setLoading(true);
          }, 5000);
          setLoading(false);
          toast.error('Email is not present, Please enter the correct email', { hideProgressBar: true, closeOnClick: true });
        }else if (res.data === 'Exam already given!!') {
          setError('');
          setTimeout(() => {
            setLoading(true);
          }, 5000);
          setLoading(false);
          toast.error('You have already finished the test once! Please contact the examiner', { hideProgressBar: true, closeOnClick: true });
        } else {
          setError('');
          setTimeout(() => {
            setLoading(true);
          }, 5000);
          setLoading(false);
          toast.error('Something went wrong', { hideProgressBar: true, closeOnClick: true });
        }
      } catch (err) {
        setError('');
        setTimeout(() => {
          setLoading(true);
        }, 5000);
        setLoading(false);
        toast.error('Error occurred. Please try again later.', { hideProgressBar: true, closeOnClick: true });
        console.error("API error:", err);
      }
    } else {
      setTimeout(() => {
        setLoading(true);
      }, 5000);
      setLoading(false);
      toast.error("Text Field Can't Be Blank", { hideProgressBar: true, closeOnClick: true });
    }
  };

  

  return (
    <div theme={theme} style={{ display: 'flex',flexDirection: 'column', justifyContent: 'space-between', height: '89vh' }}>
      <div elevation={10} style={paperStyle}>
        <div component="main" maxWidth="xs">
          <CssBaseline />
          <div
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            style={{
              padding: '25px 30px',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              borderRadius: '10px',
            }}
          >
            <h1 style={{ textAlign: 'center' }}>Login</h1>
            <form
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              noValidate
              sx={{ mt: 1 }}
              style={{ width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                placeholder="Enter your Email"
              />
              <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
              {loading ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{height: '44px'}}
                >
                  <span style={{ fontSize: '16px' }}>Get OTP</span>
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  style={{height: '44px'}}
                  disabled
                >
                  <CircularProgress disableShrink />
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>

      <div><Copyright /></div>
    </div>
  );
}
