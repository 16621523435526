import React, { useEffect, useState } from 'react';
import './questionDetails.css';
import { Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';

const QuestionDetail = ({ question, questions, submit, onSelectQuestion,handlePreviousQuestion, handleNextQuestion,id,setCurrentQuestionIndex }) => {

    const questionsPerRow = 8;
    const navigate = useNavigate();
    const [answerList,setAnswerList] = useState(()=>getAnswerList("userResponse"))
    const [currentQuestionNo,setCurrentQuestionNo]= useState(1)
    const [currentValue,setCurrentValue]= useState({})
    
    function getAnswerList(key) {
        const answer = window.sessionStorage.getItem(key);
        return answer != null ? JSON.parse(answer) : [];
    }

    useEffect(()=>{
        let currentQuestionNo=questions.findIndex(ques=> {
            return ques.id===question.id
        });
       
        setCurrentQuestionNo(currentQuestionNo+1)
        setCurrentQuestionIndex(currentQuestionNo)
        let currentAnswer=getValue(question);
        if(currentAnswer)
            setCurrentValue({id:currentAnswer.quesID,value:currentAnswer.answer})
        else setCurrentValue({})
        
    },[question])

    const handleSelectedOption=(questionId,answer)=>{
        setCurrentValue({id:questionId,value:answer})
        let userResponse=[...answerList]
        let existingIndex=userResponse.findIndex(ques=>ques.quesID===questionId)
        let obj={"answer":answer,"examID":1,"quesID":questionId,"userAns": null}
        if(existingIndex>-1){
            let apiUserAns  = questions.find((ques) => ques.id === questionId)?.userAns || null;
            userResponse[existingIndex].answer = apiUserAns !== null ? apiUserAns : answer;
        }
        else {
            let apiUserAns = questions.find((ques) => ques.id === questionId)?.userAns || null;
            obj.answer = apiUserAns !== null ? apiUserAns : answer;
            userResponse = [...userResponse, obj];
        }
        sessionStorage.setItem('userResponse',JSON.stringify(userResponse))
        setAnswerList(userResponse)
    }

    const hasAttempted=(ques)=>{
        return answerList.find((item)=>item.quesID===ques.id)
    }

    const getValue=(question)=>{
        let value={}
        if(answerList && question){
            value= answerList.find(item=>{
                return question.id===item.quesID
            })
        }
        return value
    }

    const clearResponse=(id)=>{
        let updatedAnswerList=answerList.filter(ans=> ans.quesID!==id )
        sessionStorage.setItem('userResponse',JSON.stringify(updatedAnswerList))
        setAnswerList(updatedAnswerList)
        setCurrentValue({})
    }

  return (
    <div style={{display: 'flex',justifyContent: 'space-between', width: '100%', height: '75vh'}}>
        <div className="question-detail-container">
            <div>
                {question && (
                    <div className="question-detail">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"><b>{currentQuestionNo}</b>{".  "}{question.question}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            key={question.id}
                            value={currentValue.id===question.id?currentValue.value:''}
                            onChange={(e)=>handleSelectedOption(question.id,e.target.value)}
                        >
                            <FormControlLabel value={question.option1} control={<Radio />} label={question.option1} />
                            <FormControlLabel value={question.option2} control={<Radio />} label={question.option2} />
                            <FormControlLabel value={question.option3} control={<Radio />} label={question.option3} />
                            <FormControlLabel value={question.option4} control={<Radio />} label={question.option4} />
                        </RadioGroup>
                    </FormControl>
                    </div>
                )}
            </div>
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
                <div className='prevNclearButton'>
                    <Button variant="contained" onClick={handlePreviousQuestion} disabled={currentQuestionNo===1}>Previous</Button>
                    <Button variant="contained" className='clearButton' onClick={()=>clearResponse(question.id)} disabled={!currentValue.value}>clear</Button>
                </div>
                <Button variant="contained" onClick={handleNextQuestion} disabled={currentQuestionNo===questions.length}>Next</Button>
            </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className="question-list-container">
            <div>
                <h2>Question List</h2>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {questions.map((que, index) => (
                    <div key={index} onClick={() =>{ onSelectQuestion(que)}} className={`question-list-item ${hasAttempted(que)?'marked':''} ${currentQuestionNo === index+1? 'active' : ''}`} style={{ cursor: 'pointer', width: `${100 / questionsPerRow}%`, padding: '15px',display: 'flex', justifyContent: 'center' }}>
                        {index+1}
                    </div>
                    ))}
                </div>
            </div>
            <div style={{display: 'flex',justifyContent: 'flex-end', width: '100%'}}><Button id={id} variant="contained" color='success' onClick={submit} width='70px'>Submit Section</Button></div>
        </div>
    </div>
  );
};

export default QuestionDetail;
