import React, { useEffect, useState, useRef  } from 'react';
import './recording.css';
import { Button, CircularProgress } from '@mui/material';
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from 'react-router-dom';
import RecordingTimer from '../dashboard/Timer/recordingTimer';
import RecordingTimer2 from '../dashboard/Timer/recordingTimer2';
import axios from 'axios'
import ConfirmationDialog from '../commonComponent/ConfirmationDialog';
import Typography from '@mui/material/Typography';
import ConfirmationDialogForTimer from '../commonComponent/ConfirmationDialogForTimer';



function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
    </Typography>
  );
}


const Recording = (props) => {

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [recordTime, setRecordTime] = useState(120);
  const [flag, setflag] = useState(0);
  const mimeType = 'video/webm';
  const mediaRecorder = useRef(null);
  const recordedChunks = useRef([]);
  const[timer, setTimer] = useState(120000);
  let timerId; 
  const liveVideoFeed = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [ins, setIns] = useState([]);
  const [recordVideodata, setRecordVideodata] = useState([]);
  const [load, setLoad] = useState(false);
  let idx = 1;
  let CurrentSet= localStorage.getItem('currentSet')

  const [warning, setWarning] = useState(
    localStorage.getItem('warning')
      ? Number(localStorage.getItem('warning'))
      : 0
  );

  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
      .then(res => {
        console.log("instructionsapi", res.data);
        setIns(res.data);
    });
    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );

      if (window.innerHeight === window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {
        document.getElementById('clickButton').click()
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
          let userId = localStorage.getItem('userId');
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${CurrentSet}`,
          {
            "questions": []
          });
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  // get camera permission.......................................................................................
  const getCameraPermission = async () => {
    setRecordedVideo(null);
    setflag(prev => prev + 1)
    if ("MediaRecorder" in window) {
        try {
            const videoConstraints = {
                audio: true,
                video: true,
            };
            const audioConstraints = { audio: true };
            const audioStream = await navigator.mediaDevices.getUserMedia(
                audioConstraints
            );
            const videoStream = await navigator.mediaDevices.getUserMedia(
                videoConstraints
            );
            setPermission(true);
            const combinedStream = new MediaStream([
                ...videoStream.getVideoTracks(),
                ...audioStream.getAudioTracks(),
            ]);
            setStream(combinedStream);
            liveVideoFeed.current.srcObject = videoStream;
        } catch (err) {
            alert(err.message);
        }
    } else {
        alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  // start recording..........................................................................................
  const startRecording = async() => {
    setRecordedVideo(false);
    const videoConstraints = {
      audio: true,
      video: true,
    };
    const videoStream = await navigator.mediaDevices.getUserMedia(
      videoConstraints
    );
    liveVideoFeed.current.srcObject = videoStream;
    recordedChunks.current = [];
    if (stream) {
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(recordedChunks.current, { type: mimeType });
        setRecordedVideo(URL.createObjectURL(recordedBlob));

        if (recordedChunks.current.length === 0) {
          console.error("No recorded chunks found.");
          return;
        }

        const formData = new FormData();
        formData.append("video", recordedBlob, "mediaRecorder.mp4");
        setRecordVideodata(formData);
      };

      mediaRecorder.current.onerror = (error) => {
        console.error('MediaRecorder error:', error);
        alert('Error occurred during recording. Please try again.');
        setRecording(false);
      };

      mediaRecorder.current.start();
      setRecording(true);

      // timerId = setTimeout(() => {
      //   mediaRecorder.current.stop();
      //   setRecording(false);
      //   setRecordedVideo(null);
      //   setPermission(false);
      //   liveVideoFeed.current.srcObject = null;
      //   const tracks = stream.getTracks();
      //   tracks.forEach((track) => track.stop());
      //   setRecordTime(120); 
      //   setTimer(120000);
      // }, timer);
    }
  };


  // stop recording................................................................................
  const stopRecording = async() => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setRecording(false);
      setRecordedVideo(null);

      liveVideoFeed.current.srcObject = null;
      setPermission(false);
      clearTimeout(timerId);
      setTimer(120000);
      setRecordTime(120); 

      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }

      const recordedBlob = new Blob(recordedChunks.current, { type: mimeType });
      const streamBufferLink = URL.createObjectURL(recordedBlob);
      setRecordedVideo(streamBufferLink); 

      if (recordedChunks.current.length === 0) {
        console.error("No recorded chunks found.");
        return;
      }

      const formData = new FormData();
      formData.append("video", recordedBlob, "mediaRecorder.mp4");
      setRecordVideodata(formData);

     
    }
  };

  

  //send buffer to the server........................................................................
  const sendVideoBufferToServer = () => {
    setLoad(true);
    let userId = localStorage.getItem('userId');
    axios.post(`${process.env.REACT_APP_SERVER_URL}/RecordedVideo/${userId}`,recordVideodata, {
      headers: {
        'Content-Type': 'multipart/form-data',
    },
      body: recordVideodata || [],
    })
      .then((res) => {
        setLoad(false);
      })
      .catch((error) => {
        console.error('Error sending video buffer to the server:', error);
      });
  };

  // Example function to convert video bufferArray to Base64 data URL
  const handleFinish = () => {
    let flag=Number(localStorage.getItem('warning'));
    let userId = localStorage.getItem('userId');
    let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
    axios.post(`${process.env.REACT_APP_SERVER_URL}/submitapi/`,
    {
        "userID": userId,
        "flag":flag,
        "examAnalysis":recordVideodata || [],
        "isFinishSectionFlag": true,
        "sectionID": localStorage.getItem('currentSet'),
    })
    .then(res => {
        sendVideoBufferToServer();
        if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${CurrentSet}`]:true}))
        else localStorage.setItem('submittedSet',JSON.stringify({[`set${CurrentSet}`]:true}))
        navigate('/questionsummary')
    }).catch((error) => {
      console.error('Error sending video buffer to the server:', error);
    });
  };

  const handleCloseD = (e) => {
    e.preventDefault();
    getFullScreen();
    setOpenBox(false);
  };
  
  const handleCloseA = (e) => {
    setOpenBox(true);
  }

  const openDialogForSubmit=()=>{
    setOpenTest(true)
  }

  const styles={
    fontWeight:700,
    fontSize:'1.5em',
    display:'flex',
    justifyContent:'space-between',
    margin:'20px',
    color: '#1976d2'
  }


  return (
    <>  
      <ConfirmationDialog open={openBox} handleClose={handleCloseD} submit={handleFinish}/>
      <ConfirmationDialogForTimer open={openTest} submit={handleFinish} textForSubmit={`Section time has ended, Please press YES to submit.`} />

      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', height: '91vh'}}>
        <div style={{display: 'flex', flexDirection: 'row', height: '88vh'}}>
            <div className="question-detail-container1">
            {/* <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Instructions for the test"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <b style={{color: 'blue'}}>General Instructions:-</b>
                  <br />
                  <span>The test duration is of 1 Hour and contains __ Coding questions</span><br />
                  <span>The test duration is of 1 Hour and contains __ Coding questions</span><br />
                  <span>The test duration is of 1 Hour and contains __ Coding questions</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setOpen(false)} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog> */}
            <div style={styles}>
              <span>
                {`${location.state.part}: ${location.state.title}`}
                {/* <IconButton onClick={()=>setOpen(true)}>
                  <InfoOutlinedIcon></InfoOutlinedIcon>
                </IconButton> */}
              </span>
            </div>
            <div style={{height: '50px'}}>
                {recording  && <div style={{ textAlignLast: 'center',color: '#1976d2' }}>
                  <h2>
                    <RecordingTimer2
                      email={localStorage.getItem('id')}
                      stopRecording={stopRecording}
                      recordTime={recordTime}
                      setRecordTime={setRecordTime}
                    />
                  </h2>
                </div>}
            </div>
                <main style={{display: 'flex',justifyContent: 'center',}}>  
                  <div className="video-controls"></div>
                  {recordedVideo ? (
                    <div className="recorded-player">
                      <ReactPlayer url={recordedVideo} controls={true} />
                    </div>) : (
                    !permission ? ( <div style={{ textAlign: 'center', width: '480px', height: '360px', backgroundColor: '#000000', color: '#ffffff' }}>
                      <p style={{display: 'flex', justifyContent: 'center', paddingTop: '150px'}}>No video recorded yet.</p>
                    </div>
                    ) :  ""
                  )} 
                </main>
              
                  {liveVideoFeed ? <div style={{ display: `${!permission ? 'none' : 'flex'}`, justifyContent: 'center', textAlign: 'center', height: '360px'}}>
                    <video ref={liveVideoFeed} autoPlay playsInline style={{ textAlign: 'center'}} />
                  </div> : ""}
                

                
                <div style={{display: 'flex',justifyContent: 'center', paddingTop: '25px', gap: '10px' }}>
                {!permission ? (
                    <Button onClick={getCameraPermission} type="button" variant="contained" disabled={flag >1}>
                      start
                    </Button>
                  ) : (
                    <Button onClick={startRecording} disabled={recording} type="button" variant="contained">
                      {(!recording ? (flag>1 ? "Take 2" : "Take 1") : "Recording...")}
                    </Button>
                  )}
                  
                  <Button variant="contained" onClick={stopRecording} type="button" disabled={!recording}>stop</Button>
                </div>
                
            </div>
            
            <div className="question-list-container">
              <div>
              <div style={{ textAlignLast: 'center', color: '#1976d2' }}>
                <h2>
                  <RecordingTimer
                    email={localStorage.getItem('id')}
                    stopRecording={stopRecording}
                    handleFinish={openDialogForSubmit}
                    part={location.state.part}
                    title={location.state.title}
                    queTime={location.state.queTime}
                  />
                </h2>
              </div>
                <div><h2>Instructions</h2></div>
                <div style={{border: '1px solid', padding: '10px'}}>
                {ins.map((post, index) => {
                  if(post.type === Number(localStorage.getItem('currentSet'))) {
                    return (
                      <ul>
                        <div key={index}>
                          <li>
                            {idx++}. {post.instruction}
                          </li>
                        </div>
                      </ul>
                  )}
                  })}
                </div>
              </div>

                <div style={{display: 'flex',justifyContent: 'flex-end', width: '100%'}}>
                  {load ? 
                    <Button className="run-bttn" style={{ backgroundColor: 'gray', width: '150px' }}><CircularProgress disableShrink size= '25px' color='primary' /></Button> 
                  :
                    <Button id='clickButton' variant="contained" onClick={handleCloseA} style={{ backgroundColor: 'green'}}>Submit Section</Button>
                  }
                </div>
            </div>
        </div>
        <div><Copyright /></div>
      </div>
    </>
  );
};

export default Recording;
