import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';



const McqTimer = (props) => {
    const [seconds, setSeconds] = useState((props.queTime)*60);
    const [open, setOpen] = useState(false);
    const [ins, setIns] = useState([]);
    let idx = 1;
    let CurrentSet= Number(localStorage.getItem('currentSet'));


    useEffect(() => {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
        .then(res => {
          setIns(res.data);
        });
        const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


  if(seconds === 0) {
    props.submit()
  };

  useEffect(() => {
    if(seconds === 120){
      toast.warning('Two minutes left', {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  },[seconds === 120])

  useEffect(() => {   
    if(seconds === 300){
      toast.warning('Five minutes left', {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  }, [seconds === 300])

  const minutes = Math.floor(seconds / 60);
  const formattedSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;
  const styles={
    fontWeight:700,
    fontSize:'1.5em',
    display:'flex',
    justifyContent:'space-between',
    margin:'20px'
  }


  return (
    <>
    <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions:-</b>
            <br /><br />
            {ins.map((post, index) => {
            if(post.type === CurrentSet) {
              return (
                <div key={index}>
                  <DialogContentText>
                    {idx++}. {post.instruction}
                  </DialogContentText>
                </div>
            )}
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    <div style={styles}>
      <span>
        {`${props.part}: ${props.title}`}
        <IconButton onClick={()=>setOpen(true)}>
          <InfoOutlinedIcon></InfoOutlinedIcon>
        </IconButton>
      </span>
      {seconds<1 ? <span>0:00</span> : <span>{`${minutes}:${formattedSeconds}`}</span>}
    </div>
    </>
  );
};

export default McqTimer;
