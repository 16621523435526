import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";



const RecordingTimer2 = (props) => {
    const nav = useNavigate();
    const [seconds, setSeconds] = useState(props.recordTime);
  

    useEffect(() => {
        const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

  if(seconds === 0) {
    props.setRecordTime(120);
    props.stopRecording();
  };

  const minutes = Math.floor(seconds / 60);
  const formattedSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;

  return (
    <div>
      <p>{`${minutes}:${formattedSeconds}`}</p>
    </div>
  );
};

export default RecordingTimer2;
