import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import QuestionPaper from './questionpaper';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';



function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
    </Typography>
  );
}

const QuestionDashboardSummary = () => {
  const[isSubmit, setIsSubmit] = useState("start");
  const [open, setOpen] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [queTime, setQueTime] = useState('');
  const [queCount, setQueCount] = useState('');
  const [sectionFlag, setSectionflag] = useState({
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false
  })
  const navigate = useNavigate();
  let submittedSets=JSON.parse(localStorage.getItem('submittedSet'));

  const [warning, setWarning] = useState(
    localStorage.getItem('warning')
      ? Number(localStorage.getItem('warning'))
      : 0
  );

  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }

  useEffect(() => {
    let userId = localStorage.getItem('userId');
    axios.post(`${process.env.REACT_APP_SERVER_URL}/DashboardAPI/${userId}`, {
      email: localStorage.getItem("id")
    }).then(res => {
      setQueTime(res.data.questionTime);
      setQueCount(res.data.noOfQuestion);
      setSectionflag(res.data.isFinishSectionFlag);
    })

    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );

      if (window.innerHeight === window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {
        document.getElementById('clickButton').click()
      }
    });
  }, []);


  useEffect(() => {
    const allSectionsSubmitted = Object.values(sectionFlag).every(flag => flag);

    if (allSectionsSubmitted) {
      handleCloseSubmit();
    }
  }, [sectionFlag]);


  const handleFinishExam = () => {
    setOpen(false);
    let email = localStorage.getItem('id');
    let userId = localStorage.getItem('userId');
    axios.post(`${process.env.REACT_APP_SERVER_URL}/isfinishedapi/${userId}`,
      {
        "email": email,
      })
    .then(res => {
      navigate('/exit')
    });
  }


const getSubmittedStatus=(set)=>{
  return submittedSets ? submittedSets[`set${set}`]:false
};

const handleClose = (e) => {
  e.preventDefault();
  getFullScreen();
  setOpen(false);
};

const handleCloseA = (e) => {
  setOpen(true);
}

const handleCloseSubmit = (e) => {
  setOpenSubmit(true);
}

  return (
    <>
    <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to finish this test ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button onClick={handleFinishExam} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSubmit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'You have completed all sections now, you can click'} <b> finish test</b> {'to conclude the examination.'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleFinishExam} autoFocus>
            Finish Test
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', height: '91vh'}}>
      {queTime && queCount && <div>
            <div style={{display:"flex", alignItems: 'center',width:"90vw",margin: '10px auto',}}>
                <div style={{width:"20%", display:"flex", justifyContent: 'center'}}>
                <h3>Sections</h3>
                </div>
                <div style={{width:"20%", display:"flex", justifyContent: 'center'}}>
                  <h3>Type</h3>
                </div>
                <div style={{width:"20%", display:"flex", justifyContent: 'center'}}>
                <h3>No of Questions</h3>
                </div>
                <div style={{width:"20%", display:"flex", justifyContent: 'center', alignItems: 'baseline'}}>
                <h3>Time</h3><span style={{ marginLeft:"5px", fontSize: "12px"}}>(in mins)</span>
                </div>
                <div style={{width:"20%", display:"flex", justifyContent: 'center'}}>
                <h3>Status</h3>
                </div>
            </div>
          <>
          {/* <QuestionPaper part="Part(A)" title="Logical Reasoning" queTime={queTime['1']} queCount={queCount['1']} status={isSubmit} questionSet={1} isSubmitted={getSubmittedStatus(1)} route={'/mcqquestion'} sectionFlag={sectionFlag['1']} /> */}
          {/* <QuestionPaper part="Part(B)" title="Quantitative" queTime={queTime['2']} queCount={queCount['2']} status={isSubmit} questionSet={2} isSubmitted={getSubmittedStatus(2)} route={'/mcqquestion'} sectionFlag={sectionFlag['2']} /> */}
          <QuestionPaper part="Part(A)" title="Coding" queTime={queTime['3']} queCount={queCount['3']} status={isSubmit} questionSet={3} isSubmitted={getSubmittedStatus(3)} route={'/questionDashboard'} sectionFlag={sectionFlag['3']} />
          <QuestionPaper part="Part(B)" title="English Writing" queTime={queTime['4']} queCount={queCount['4']} status={isSubmit} questionSet={4} isSubmitted={getSubmittedStatus(4)} route={'/dashboard-exam'} sectionFlag={sectionFlag['4']} />
          {/* <QuestionPaper part="Part(E)" title="Video Recording" queTime={queTime['5']} queCount={queCount['5']} status={isSubmit} questionSet={5} isSubmitted={getSubmittedStatus(5)} route={'/recording'} sectionFlag={sectionFlag['5']} /> */}
          
          <div style={{display:"flex", justifyContent: 'flex-end',width:"96vw",paddingTop:"20px",}}>
              <Button variant="contained" id="clickButton" style={{ backgroundColor: 'green' }} onClick={Object.values(sectionFlag).every(flag => flag) ? handleCloseSubmit : handleCloseA}>Finish Test</Button>
          </div>
          </>
          </div>
          }

          <div>
              <Copyright />
          </div>
      </div>
    </>
  );
};

export default QuestionDashboardSummary;
