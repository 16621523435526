import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from 'react';

export default function Instructions(props) {
  const [ins, setIns] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
    .then(res => {
      setIns(res.data);
    });
  }, []);
  
  const nav = useNavigate();
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/agreedapi/`, {
      email: localStorage.getItem("id")
    }).then(res => {
        nav('/questionDashboard');
    })
  };
  
  let idx = 1;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Instructions For The Test"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions:-</b>
          </DialogContentText>
          <br/>
          {ins.map((post, index) => {
            if(post.type === 1) {
              return (
                <div key={index}>
                  <DialogContentText>
                    {index+1}. {post.instruction}
                  </DialogContentText>
                  <br/>
                </div>
            )
            }
          })}

          <DialogContentText>
            <b style={{color: 'blue'}}>Exam Instructions:-</b>
          </DialogContentText>
          <br/>
          {ins.map((post, index) => {
            if(post.type === 2) {
              return (
                <div key={index}>
                  <DialogContentText>
                    {idx++}. {post.instruction}
                  </DialogContentText>
                  <br/>
                </div>
            )
            }
          })}

          <DialogContentText>
              By clicking on Start, you agree to follow the above mentioned instructions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus style={{marginTop: '-15px'}}>Start</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
