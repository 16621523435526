import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import QuesComponent from '../QuesComponents/QuesComponent';
import { DialogContent, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const QuestionPaper = (props) => {
    const[isSubmit, setIsSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
  
    const paperStyle = {
      padding: '5px 20px',
      height: '10vh',
      width: '90vw',
      margin: '10px auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    function getFullScreen() {
      return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }

    useEffect(() => {
      getFullScreen();
      window.addEventListener('resize', (evt) => {
        if (window.innerHeight === window.screen.height) {
          console.log('FULL SCREEN');
        } else if(document.getElementById('clickButton')) {
          document.getElementById('clickButton').click()
        }
      });
    }, []);

    const handleClose = (e) => {
      e.preventDefault();
      getFullScreen();
      setOpen(false);
    };

    const handleCloseA = (e) => {
      setOpen(true);
    }

    const eventHandler =()=>{
      localStorage.setItem('currentSet',null)
      if(props.questionSet) {
        localStorage.setItem('currentSet',props.questionSet)
      }
      navigate(`${props.route}`,{
        state:{
          part:props.part,
          title:props.title,
          queTime:props.queTime,
          currentSet:props.questionSet,
        }
      });
    }
  
    return (
      <> 
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {/* {props.part === "Part(A)" || props.part === "Part(B)" ? (
            <>
              This section comprises {props.queCount} multiple-choice questions (MCQs) to be completed within {props.queTime} minutes. Please click <strong>YES</strong> to begin.
            </>
          ) : ""} */}
          {props.part === "Part(A)" ? (
            <>
              This section comprises {props.queCount} coding questions to be completed within {props.queTime} minutes. Please click <strong>YES</strong> to begin.
            </>
          ) : ""}
          {props.part === "Part(B)" ? (
            <>
              This section comprises {props.queCount} English Writing question to be completed within {props.queTime} minutes. Please click <strong>YES</strong> to begin.
            </>
          ) : ""}
          {/* {props.part === "Part(E)" ? (
            <>
              This section involves a video recording task to be completed within {props.queTime} minutes and Please click <strong>YES</strong> to begin.
            </>
          ) : ""} */}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={eventHandler} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>

        <Paper elevation={7} style={paperStyle}>
          <div style={{width:"20%",display:"flex", justifyContent: 'center' }}>
              <p style={{ margin: 0 }}><b>{props.part}</b></p>
          </div>
          <div style={{width:"20%",display:"flex", justifyContent: 'center' }}>
              <p style={{ margin: 0 }}><b>{props.title}</b></p>
          </div>
          <div style={{width:"20%",display:"flex", justifyContent: 'center' }}>
              <p style={{ margin: 0 }}><b>{props.queCount}</b></p>
          </div>
            <div style={{width:"20%",display:"flex", justifyContent: 'center',color: 'blue' }}>
              <p style={{ margin: 0 }}>{props.queTime}</p>
            </div>
          <div style={{width:"20%",display:"flex", justifyContent: 'center',color: 'blue' }}>
              <Button id='clickButton' variant="contained" onClick={handleCloseA} disabled={props.sectionFlag}>{props.sectionFlag ? <span style={{color:"#2C2C2C"}}>Submitted</span> : props.status}</Button>
          </div>
        </Paper>
       
      </>
    );
  };
  
  export default QuestionPaper;