import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import QuesComponent from '../../QuesComponents/QuesComponent';
import Button from '@mui/material/Button';
import axios from 'axios';
import ConfirmationDialog from '../../commonComponent/ConfirmationDialog';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' Spanidea Systems'}
    </Typography>
  );
}

const QuestionDashboard = (props) => {
  const [ins, setIns] = useState([]);
  const [insOpen, setInsOpen] = useState(false);
  let idx = 1;
  const navigate = useNavigate();

  const [warning, setWarning] = useState(
    localStorage.getItem('warning')
      ? Number(localStorage.getItem('warning'))
      : 0
  );

  const [codingQuestions,setCodingQuest]=useState(
    localStorage.getItem('codingQuestions')
      ?JSON.parse(localStorage.getItem('codingQuestions')):[])


  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }
  
  let submittedSets=JSON.parse(localStorage.getItem('submittedSet'));
  let currentSet= Number(localStorage.getItem('currentSet'))

  useEffect(() => {
    const fetchData = async() => {
      try {
        let currentSet = Number(localStorage.getItem('currentSet'));
        let userId = localStorage.getItem('userId');
        let questions = [
          {
            "quesID": Number(localStorage.getItem('codingQuesId', codingQuestions.id)),
            "answer": localStorage.getItem('CodingQuestion'),
          }
        ]
        let response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/CreateExamAPI/${userId}/${currentSet}`,
          {
            questions
          }
        );
        setCodingQuest(response.data.questions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    getFullScreen();
    window.addEventListener('resize', (evt) => {
      localStorage.setItem(
        'warning',
        localStorage.getItem('warning')
          ? Number(localStorage.getItem('warning')) + 1
          : 0
      );

      if (window.innerHeight === window.screen.height) {
        console.log('FULL SCREEN');
      } else if(document.getElementById('clickButton')) {
        document.getElementById('clickButton').click()
      }
    });

    // setInterval api calling each minute..................................
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);

  }, []);


   useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/instructionsapi/`,{email: localStorage.getItem("id")})
        .then(res => {
          setIns(res.data);
        });
  }, [])

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseD = (e) => {
    e.preventDefault();
    getFullScreen();
    setOpen(false);
  };

  const submit = () => {
    setOpen(false);
    let currentSet= Number(localStorage.getItem('currentSet'))
    if(currentSet===3) {
      // let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
      // if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${currentSet}`]:true}))
      // else localStorage.setItem('submittedSet',JSON.stringify({[`set${currentSet}`]:true}))
      // localStorage.removeItem('currentSet')
      // localStorage.removeItem('codingQuestions')
      // navigate('/questionsummary')

    let flag=Number(localStorage.getItem('warning'));
    let userId = localStorage.getItem('userId');
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/submitapi/`, 
        {
          "userID": userId,
          "flag":flag,
          "examAnalysis": [],
          "isFinishSectionFlag": true,
          "sectionID": currentSet,
      })
      .then((res) => {
        let submittedSets=JSON.parse(localStorage.getItem('submittedSet'))
          localStorage.removeItem('codingQuesId');
          localStorage.removeItem('CodingQuestion')
          if(submittedSets) localStorage.setItem('submittedSet',JSON.stringify({...submittedSets,[`set${currentSet}`]:true}))
          else localStorage.setItem('submittedSet',JSON.stringify({[`set${currentSet}`]:true}))
          navigate('/questionsummary');
      });
    }
  };

  const getSubmittedStatus=(set,qid)=>{
    return submittedSets ? submittedSets[`set${set}`]?submittedSets[`set${set}`][qid]:false:false
  };

  const styles={
    fontWeight:700,
    fontSize:'1.5em',
    display:'flex',
    justifyContent:'space-between',
    margin:'20px',
    color: '#1976d2'
  }

  
  return (
    <>
      <ConfirmationDialog open={open} handleClose={handleCloseD} submit={submit}/>
      <Dialog
        open={insOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Instructions for the section"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions:-</b>
            <br /><br />
            {ins.map((post, index) => {
            if(post.type === currentSet) {
              return (
                <div key={index}>
                  <DialogContentText>
                    {idx++}. {post.instruction}
                  </DialogContentText>
                </div>
            )}
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setInsOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', height: '89vh'}}>
        <div>
          <div style={{display:"flex", alignItems: 'center', justifyContent: 'space-between',width:"88vw"}}>
          <div style={styles}>
            <span>
              Part(A) : Coding
              {/* <IconButton onClick={()=>setInsOpen(true)}>
                <InfoOutlinedIcon></InfoOutlinedIcon>
              </IconButton> */}
            </span>
          </div>
          </div>
          {codingQuestions && codingQuestions.map((post, index) => {
            return (
              <span key={index}>
                <QuesComponent
                  isCode={!post.isCode}
                  idx={index}
                  pid={post.id}
                  questionname={post.title}
                  marks={post.marks}
                  linkquestion={post.question}
                  isSubmit={getSubmittedStatus(currentSet,post.id)}
                />
              </span>
            );
          })}
        
          <div style={{display:"flex",flexDirection:"row-reverse", padding:"0 3%", marginTop:"5%"}}>
            <Button
              id="clickButton"
              variant="contained"
              onClick={handleClickOpen}
              style={{ backgroundColor: 'green' }}
            >
              Submit Section
            </Button>
          </div>
        </div>

        <div><Copyright /></div>
      </div>
    </>
  );
};

export default QuestionDashboard;
