import React, {useEffect} from 'react'
import Typography from '@mui/material/Typography';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
      SpanIdea System
      {new Date().getFullYear()}
      {'.'}
      </Typography>
    );
  }

const Exit = () => {
  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    document.exitFullscreen();
  });

  return (
    <>
        
        <div style={{position:"absolute",left:"50%",top:"25%",transform: "translateX(-50%)"}}>
        <h1 style={{textAlign:"center"}}>Thank you! <br />Your Test is Submitted Successfully.</h1>
        </div>
        
        
        <Copyright style={{position:"absolute", bottom:"0px",left:"50%",transform: "translateX(-50%)"}} sx={{ mb: 4 }} />
    </>
  )
}

export default Exit
