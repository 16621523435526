import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmationDialogForTimer = (props) => {


  return (
      <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.textForSubmit}
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.submit} autoFocus>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmationDialogForTimer;
