import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import Timer from '../dashboard/Timer/Timer';
import { useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const NavBar = (props) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${
        name.split(' ')[1] ? name.split(' ')[1][0] : ''
      }`,
    };
  }
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const { updateTime } = props;
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const location = useLocation();

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [startTimer, setStartTimer] = useState(false);
  const [id, setId] = useState('');
  const [clgid, setClgID] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState(true);

  React.useEffect(() => {
    if (!startTimer) {
      if (location.pathname === '/questionDashboard' || location.pathname === '/questionsummary') {
        setStartTimer(true);
        setTitle(false);
        setId(localStorage.getItem('id'));
        setClgID(localStorage.getItem('collegeName') || "");
        setName(localStorage.getItem('name'));
      }
    } else {
      if (location.pathname === '/exit') {
        setStartTimer(false);
        setTitle(true);
        setId('');
      }
    }
  }, [location.pathname]);


  return (
    <AppBar position="static">
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 30px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            // variant="h6"
            // noWrap
            // component="a"
            // sx={{
            //   mr: 2,
            //   display: { lg: 'flex' },
            //   fontFamily: 'monospace',
            //   fontWeight: 700,
            //   letterSpacing: '.3rem',
            //   color: 'inherit',
            //   textDecoration: 'none',
            // }}
          >
            <img
              src="https://spanidea.com/in/wp-content/uploads/2022/08/png-white-logo-300x53.png"
              style={{ height: 30, width: 210 }}
            />
          </div>
          {/*------------------------------------------------- yaha Right border chiye-------------------------------------------------- */}

          {title && <p style={{ margin: 0 }}>{props.name}</p>}
          {id && (
            <div style={{ display: 'flex', gap: '15px' }}>
              <div className="timer" 
                // style={{ display: 'flex', alignItems: 'baseline' }}
              >
                {startTimer && <Timer update={updateTime} />}
                {/* <span style={{ marginLeft:"5px", fontSize: "12px"}}>remaining time</span> */}
              </div>
              <BootstrapTooltip
                title={
                  <>
                    <h3> {name.toUpperCase()} </h3>
                    <h3> {id} </h3>
                    {/* <h3> {clgid} </h3> */}
                  </>
                }
              >
                <Avatar {...stringAvatar(`${name.toUpperCase()}`)} />
              </BootstrapTooltip>
            </div>
          )}
        </div>
      </div>
    </AppBar>
  );
};
export default NavBar;
