import React, { useEffect } from 'react';
import Instructions from './Instructions';

const Start = () => {
  function getFullScreen() {
    return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }
useEffect(() => {
    getFullScreen();
    window.addEventListener('resize', (evt) => { 
      if (window.innerHeight == window.screen.height) {
        console.log('FULL SCREEN');
      } 
      else if(document.getElementById('clickButton')) {
        document.getElementById('clickButton').click()
      }
  });
  });
  return (
    <>
        {true && <Instructions />}
    </>
  )
}

export default Start;
