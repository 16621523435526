import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';



const RecordingTimer = (props) => {
    const nav = useNavigate();
    const [seconds, setSeconds] = useState((props.queTime)*60);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

  if(seconds === 0) {
    props.stopRecording();
    props.handleFinish()
  };

  if(seconds === 60){
    toast.warning('One minute left', {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
    })
  }

  const minutes = Math.floor(seconds / 60);
  const formattedSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;

  const styles={
    fontWeight:700,
    fontSize:'1.5em',
    display:'flex',
    justifyContent:'center',
    margin:'20px'
  }

  return (
    <>
    <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Instructions For The Test"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'blue'}}>General Instructions:-</b>
            <br />
            <span>The test duration is of 1 Hour and contains __ Coding questions</span><br />
            <span>The test duration is of 1 Hour and contains __ Coding questions</span><br />
            <span>The test duration is of 1 Hour and contains __ Coding questions</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    <div style={styles}>
      {seconds<1 ? <span>0:00</span> : <span>{`${minutes}:${formattedSeconds}`}</span>}
    </div>
    </>
  );
};

export default RecordingTimer;
